<template>
  <div class="input">

    <!-- Drag and drop uploader block -->
    <div v-if="showUploader">
      <!-- <div class="input-description">ทางสามารถลากไฟล์เอกสารจากเครื่องของทางมายังกล่องสี่เหลี่ยมด้านล่างเพื่ออัพโหลดไฟล์ผ่านระบบของเราได้ทันที รองรับไฟล์ <em>*.jpg, *.png, *.pdf, *.doc, *.docx, *.xls, *.xlsx</em> และรองรับขนาดไฟล์<em>ไม่เกิน 50MB ต่อไฟล์</em></div> -->
      <div class="file-drop" v-cloak @drop.prevent="addDragFile" @dragover.prevent>

        <div v-if="files.length==0">
          <figure>
            <img src="@/assets/img/icon/upload.svg" alt="">
          </figure>
          <h4>Drag &amp; Drop a file here</h4>
          <button @click="$refs.file.click()" type="button" class="btn">Browse a file</button>
          <input type="file" ref="file" v-on:change="addBrowseFile()" :disabled="uploading" multiple  accept="image/png, image/jpeg, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
        </div>

        <ul v-if="files.length" class="uploading-file">
          <li v-for="(file,id) in fileProgress" :key="id" class="list-group-item mb-3 border-top">
            {{ file.name }}
            <div class="preload">
              <span class="preload-bar" :style="{width: file.percentage+'%'}"></span>
            </div>
          </li>
        </ul>

      </div>
    </div>
    <!-- end: Drag and drop uploader block -->

    <!-- Uploaded files list -->
    <div 
      v-if="file_uploaded.row > 0" 
      class="uploaded-list"
      :class="{'mt-0': showUploader !== true}"
    >
      <h5 class="uploaded-title" v-if="showUploader">ไฟล์ที่อัพโหลดแล้ว</h5>
      <ul>
        <li v-for="(file,id) in file_uploaded.result" :key="id" class="list-group-item mb-3 border-top">
          <i class="fa fa-download fa-lg"></i>
          <a :href="file.url" target="_blank">
            {{ file.name }}
          </a>

          <a href="#" v-if="showUploader" class="remove-item" @click="removeFile(file)">
            <i class="fa fa-trash"></i> delete
          </a>
        </li>
      </ul>
    </div>
    <!-- end: Uploaded files list -->

  </div>
</template>

<script>
import {apiRequest, apiFileUpload} from '@/utils/axios/axiosInstance.js';

export default {
  name: 'SingleFileUploader',
  props: {
    section: {
      type: String,
      default: ''
    },
    parentId: {
      type: String,
      default: ''
    },
    relateId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    showUploader: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      // use when get data or upload data
      // system will disable input and button
      uploading: true,

      // uploading file list and progress value
      files: [],
      fileProgress: [],
      count_uploading: 0,

      // uploaded file list data
      file_uploaded: {
        row: 0,
        result: {}
      },
    }
  },
  created() {
    if(this.section==='' || this.type==='' || this.parendId==='') {
      console.log('No section or parent id data parameter !!!');
    }else{
      this.loadPage();
    }
  },
  methods: {

    /**
    * load uploaded file list
    */
    loadPage() {  
      apiRequest
        .get('/file' , {
          params: {
            section:    this.section,
            type:       this.type,
            parent_id:  this.parentId,
            relate_id:  this.relateId,
          }
        })
        .then( (res) => {
          this.file_uploaded = res.data; 
          // console.log('uploaded file');
          // console.log(this.file_uploaded);
          this.uploading = false;
        });
    },

    /**
    * for handle select file from buttom
    */
    addBrowseFile() {
      // if detect on uploading process, system will reject this process
      if(this.uploading) return;

      // prepare file data for upload
      this.files = this.$refs.file.files;
      for (let i = 0; i < this.files.length; i++) {
        if(this.files[i].size>=50000000) {
          alert('ขนาดไฟล์เกิน 50MB กรุณาลดขนาดไฟล์ก่อน upload')
          this.files = [];
          this.fileProgress = [];
          return;
        }else{
          this.fileProgress.push({
            name: this.files[i].name,
            size: this.files[i].size,
            percentage: 0,
          })
        }
      }

      // upload file
      this.activeUploadFile();
    },

    /**
     * for handle select file from drag and drop
     */ 
    addDragFile(e) {
      // if detect on uploading process, system will reject this process
      if(this.uploading) return;

      // prepare file data for upload
      let files = e.dataTransfer.files;
      [...files].forEach((file, i) => {
        if(this.files[i].size>=50000000) {
          alert('ขนาดไฟล์เกิน 50MB กรุณาลดขนาดไฟล์ก่อน upload')
          this.files = [];
          this.fileProgress = [];
          return;
        }else{
          this.files.push(file);
          this.fileProgress.push({
            name: this.files[i].name,
            size: this.files[i].size,
            percentage: 0,
          })
        }
      });

      // upload file
      this.activeUploadFile();
    },

    /**
     * upload file by file
     */
    activeUploadFile() {
      if(this.files.length > 0) {
        this.count_uploading = this.files.length;
        this.uploading = true;
        for (let i = 0; i < this.files.length; i++) {
          this.uploadFile(i, this.files[i]);
        }
      }
    },

    /**
     * upload file item process
     */
    uploadFile(i, file) {
      let formData = new FormData();
      formData.append('file', file);
      formData.append("section",  this.section);
      formData.append("parent_id",this.parentId);
      formData.append("relate_id",this.relateId);
      formData.append("type",this.type);

      apiFileUpload
        .post('/file/upload', formData, {
          onUploadProgress: function(progressEvent) {
            this.fileProgress[i].percentage = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          }.bind(this)
        })
        .then(() => {
          this.count_uploading--;

          if(this.count_uploading==0) {
            this.files = [];
            this.fileProgress = [];
            this.uploading = false;

            this.loadPage();
          }
        })
    },

    /**
     * remove file item
     */
    removeFile(file) {
      let conf = confirm('คุณต้องการจะลบไฟล์ '+file.name+' ใช่หรือไม่?');
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/file/remove' , {
            file_id: file.fileID
          })
          .then( (res) => {
            if(res.data.status==200) this.removeUploadedItemByID(file.fileID);
            this.$store.commit('setLoaderShow', false);
          });
      }
    },

    /**
     * remove file from uploaded list by file id
     */
    removeUploadedItemByID(fileID) {
      let uploaded = this.file_uploaded.result.filter((f) => {
        return f.fileID!=fileID;
      });
      this.file_uploaded.result = uploaded;
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.preload {
  display: block;
  margin: 5px 0 0 0;
  font-size: 0;
  line-height: 0;
  height: 5px;
  width: 100%;
  background: #eeeeee;
}
.preload-bar {
  display: block;
  width: 0;
  height: 100%;
  background: #39f;
}

.file-drop {
  position: relative;
  margin-top: 5px;
  padding: 15px 0;
  border-radius: 10px;
  border: 2px dashed #A2A2A2;
  background: #f2f9ff;
  text-align: center;
}
.file-drop img {
  width: 60px;
}
.file-drop h4 {
  font-size: 16px;
  line-height: 24px;
}
.file-drop .btn {
  margin-top: 5px;
  padding: 2px 20px;
  border-radius: 20px;
  background: #444;
  font-size: 12px;
  line-height: 18px;
}
.file-drop input {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1px;
  height: 1px;
  opacity: 0.0;
}

.uploading-file {
  margin: 0 20px;
  text-align: left;
}
.uploading-file li {
  padding: 10px;
  background: #fff;
}
.uploading-file li+li {
  margin-top: 5px;
}


.uploaded-list {
  margin: 10px 0 0 0;
}
.uploaded-list.mt-0 {margin-top: 0;}
.uploaded-title {
  font-size: 16px;
  font-weight: 500;
}
.uploaded-list li {
  position: relative;
  padding: 0 0 5px 0;
}
.uploaded-list li:hover {
  background: #fffff2;
}
.uploaded-list li+li {
  padding-top: 5px;
  border-top: dashed 1px #ccc;
}
.uploaded-list .remove-item {
  position: absolute;
  top: 5px;
  right: 0;
  color: #c00;
}
</style>
